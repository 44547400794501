<template>
  <Layout>
    <PageHeader :title="title" :items="items" />

    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <form class="needs-validation" @submit.prevent="Form">
            <div class="card-body">
              <div class="row mt-2">
                <h5 class="font-size-14 mb-1"></h5>
                <div class="col-md-4 col-sm-6">
                  <div class="mb-3 position-relative">
                    <code>* </code><label for="branchId">สาขา:</label>
                    <multiselect
                      id="branchId"
                      v-model="tooltipform.branchId"
                      :options="localData"
                      :show-labels="false"
                      :class="{
                        'is-invalid':
                          submitform && $v.tooltipform.branchId.$error,
                      }"
                      label="nameTh"
                      track-by="nameTh"
                    >
                      <span slot="noResult">ไม่พบข้อมูล</span></multiselect
                    >

                    <div
                      v-if="submitform && $v.tooltipform.branchId.$error"
                      class="invalid-tooltip"
                    >
                      <span v-if="!$v.tooltipform.branchId.required">{{
                        error
                      }}</span>
                    </div>
                  </div>
                </div>
                <div class="col-md-4 col-sm-6">
                  <div class="mb-3 position-relative">
                    <code>* </code>
                    <label for="nameTh">เดือน</label>:
                    <multiselect
                      v-model="tooltipform.month"
                      :options="optionMonth"
                      label="name"
                      track-by="id"
                      :show-labels="false"
                      placeholder=""
                      :class="{
                        'is-invalid': submitform && $v.tooltipform.month.$error,
                      }"
                    >
                    </multiselect>
                    <div
                      v-if="submitform && $v.tooltipform.month.$error"
                      class="invalid-tooltip"
                    >
                      <span v-if="!$v.tooltipform.month.required">{{
                        error
                      }}</span>
                    </div>
                  </div>
                </div>
                <div class="col-md-2 col-sm-6">
                  <div class="mb-3 position-relative">
                    <code>* </code>
                    <label for="nameTh">ปี</label>:
                    <multiselect
                      v-model="tooltipform.year"
                      :options="yearOption"
                      label="nameTh"
                      track-by="nameTh"
                      :show-labels="false"
                      placeholder=""
                      :class="{
                        'is-invalid': submitform && $v.tooltipform.year.$error,
                      }"
                    >
                    </multiselect>
                    <div
                      v-if="submitform && $v.tooltipform.year.$error"
                      class="invalid-tooltip"
                    >
                      <span v-if="!$v.tooltipform.year.required">{{
                        error
                      }}</span>
                    </div>
                  </div>
                </div>

                <div class="col-md-2 col-sm-6">
                  <div class="mb-3 position-relative">
                    <code>* </code><label for="year">เป้า:</label>
                    <input
                      v-model="tooltipform.target"
                      class="form-control input-placeholder"
                      placeholder=""
                      :class="{
                        'is-invalid':
                          submitform && $v.tooltipform.target.$error,
                      }"
                    />
                    <div
                      v-if="submitform && $v.tooltipform.target.$error"
                      class="invalid-tooltip"
                    >
                      <span v-if="!$v.tooltipform.target.required">{{
                        error
                      }}</span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="text-end">
                <button type="submit" class="btn btn-success">บันทึก</button>
              </div>
            </div>
          </form>

          <hr />
        </div>
      </div>
    </div>
  </Layout>
</template>

<script>
import {
  required,
  // email,
  // minLength,
  // sameAs,
  // maxLength,
  // numeric,
  // url,
  // alphaNum,
} from "vuelidate/lib/validators";

import Swal from "sweetalert2";
import useNetw from "@useNetw";
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import Multiselect from "vue-multiselect";
// import DatePicker from "vue2-datepicker";

/**
 * Form validation component
 */
export default {
  components: {
    Layout,
    PageHeader,
    Multiselect,
    // DatePicker,
    /*Switches,
    NumberInputSpinner,*/
  },
  page: {
    title: appConfig.salesTarget,
    meta: [
      {
        name: "description",
        content: appConfig.salesTarget,
      },
    ],
  },

  data() {
    return {
      Hide: false,
      enabled: false,
      submitform: false,
      submitformAddress: false,
      modalUpdateAddress: false,
      submitUpdateAddress: false,

      title: "เพิ่มการตั้งค่าเป้าการขาย",
      items: [
        {
          text: "ฝ่ายขาย",
          active: true,
        },

        {
          text: appConfig.salesTarget,
          href: "/sales-target",
        },
        {
          text: "เพิ่มการตั้งค่าเป้าการขาย",
          active: true,
        },
      ],
      optionMonth: [
        { id: "01", name: "มกราคม" },
        { id: "02", name: "กุมภาพันธ์" },
        { id: "03", name: "มีนาคม " },
        { id: "04", name: "เมษายน" },
        { id: "05", name: "พฤษภาคม" },
        { id: "06", name: "มิถุนายน" },
        { id: "07", name: "กรกฎาคม" },
        { id: "08", name: "สิงหาคม" },
        { id: "09", name: "กันยายน" },
        { id: "10", name: "ตุลาคม" },
        { id: "11", name: "พฤศจิกายน" },
        { id: "12", name: "ธันวาคม" },
      ],
      optionYear: [
        { id: "2022" },
        { id: "2021" },
        { id: "2020" },
        { id: "2019" },
      ],
      data: this.$route.params.data,
      localDataBranch: [], //เป็น-array Id
      localData: [],
      value: null,
      value1: null,
      dataTest: [],
      options: [],

      startIndex: "",
      endIndex: "",
      loading: undefined,
      total_pages: "",
      page: "",
      per_page: "",
      totalRecord: "",
      rowData: [], //? Data ที่ต่อ API
      // rowUser: [],
      rowAddress: [],
      currentPage: 1,
      perPage: 5,
      pageOptions: [5, 10, 25, 50, 100],
      yearOption: [],
      filterOn: [],
      sortBy: "age",
      sortDesc: false,
      paginatedData: "",
      filter: {
        nameThPros: "",
        familyNameThPros: "",
        licensePlate: "",
        regCode: "",
        familyNameTh: "",
        nameTh: "",
        regDate: "",
        paymentCode: "",
      },
      selectedUsers: [],
      isSelectedAll: false,

      selectMode: "multi",
      selected: [],

      totalPageVe: "",
      perPageVe: 10,
      currentPageVe: 1,
      totalRecordVe: "",
      pageOptionsVe: [5, 10, 25, 50, 100],

      totalPagePros: "",
      perPagePros: 10,
      currentPagePros: 1,
      totalRecordPros: "",
      pageOptionsPros: [5, 10, 25, 50, 100],

      error: "กรุณาตรวจสอบข้อมูลอีกครั้ง.",

      lavelOption: [
        { text: "สูง", value: "3" },
        { text: "กลาง", value: "2" },
        { text: "ต่ำ", value: "1" },
        { text: "อื่นๆ", value: "0" },
      ],

      occOption: [],
      salaryOption: [
        { nameTh: "ต่ำกว่า 10,000", id: 0 },
        { nameTh: "10,000 - 19999", id: 1 },
        { nameTh: "20000-29999", id: 2 },
        { nameTh: "100000 บาทขึ้นไป", id: 3 },
      ],
      mobilePhone: "",
      vehicaleOption: [],
      contactTypeOption: [],
      customerOption: [],
      prospectOption: [],
      cusSrcOption: [],
      prefixOPtion: [],
      provinces: [],
      amphures: [],
      districts: [],
      index: "",
      tooltipform: {
        branchId: "",
        month: "",
        year: "",
        target: "",
      },
      provinceId: "",
      amphurId: "",
      districtId: "",
    };
  },
  validations: {
    tooltipform: {
      branchId: {
        required,
      },
      month: {
        required,
      },
      year: {
        required,
      },
      target: {
        required,
      },
    },
  },
  mounted() {
    const user = JSON.parse(localStorage.getItem(appConfig.storageUserKey));
    const arrayBranch = [];
    user.userAccessBranch.forEach((item) => {
      arrayBranch.push(item.branchId);
      if (user.branchId == item.branchId) {
        this.tooltipform.branchId = {
          branchId: item.branchId,
          nameTh: item.nameTh,
        };
      } else if (this.data != null || this.data != undefined) {
        if (this.data.branchName === item.nameTh) {
          this.tooltipform.branchId = {
            branchId: item.branchId,
            nameTh: item.nameTh,
          };
        }
      }
    });
    this.localDataBranchIdUser = arrayBranch;
    this.branchId = user.branchId;
    this.localData = user.userAccessBranch;
    if (this.data != null || this.data != undefined) {
      this.tooltipform.month = {
        name: this.checkMonth(this.data.month),
        id: this.data.month,
      };
      this.tooltipform.year = { nameTh: this.data.year };
      this.tooltipform.target = this.data.target;
    }
  },
  computed: {
    fullAddress: function () {
      //

      return `${
        this.tooltipform.buildingNumber != ""
          ? "" + this.tooltipform.buildingNumber
          : this.tooltipform.buildingNumber
      } ${
        this.tooltipform.townshipNumber != ""
          ? "หมู่" + " " + this.tooltipform.townshipNumber
          : this.tooltipform.townshipNumber
      } ${
        this.tooltipform.townshipName != ""
          ? "" + this.tooltipform.townshipName
          : this.tooltipform.townshipName
      } ${
        this.tooltipform.subLane != ""
          ? "ตรอก" + " " + this.tooltipform.subLane
          : this.tooltipform.subLane
      } ${
        this.tooltipform.lane != ""
          ? "ซอย" + " " + this.tooltipform.lane
          : this.tooltipform.lane
      } ${
        this.tooltipform.streetName != "" &&
        this.tooltipform.streetName != undefined
          ? "ถนน" + " " + this.tooltipform.streetName
          : ""
      } ${
        this.districtId != "" &&
        this.districtId != undefined &&
        this.districtId != null &&
        this.districtId.nameTh != "" &&
        this.districtId.nameTh != undefined &&
        this.districtId.nameTh != null
          ? "ตำบล" + this.districtId.nameTh
          : ""
      } ${
        this.amphurId != "" &&
        this.amphurId != undefined &&
        this.amphurId != null &&
        this.amphurId.nameTh != "" &&
        this.amphurId.nameTh != undefined &&
        this.amphurId.nameTh != null
          ? "อำเภอ" + this.amphurId.nameTh
          : ""
      } ${
        this.provinceId != "" &&
        this.provinceId != null &&
        this.provinceId != undefined &&
        this.provinceId.nameTh != null &&
        this.provinceId.nameTh != "" &&
        this.provinceId.nameTh != undefined
          ? "จังหวัด" + this.provinceId.nameTh
          : ""
      }  ${
        this.tooltipform.postCode != ""
          ? "รหัสไปรษณีย์" + " " + this.tooltipform.postCode
          : ""
      }
      `;
    },
  },
  created() {
    this.getContactType();
    this.inittahun();
  },
  methods: {
    inittahun() {
      let currentYear = new Date().getFullYear();
      let earliestYear = 2001;
      let years = [];
      let data = "";
      while (currentYear >= earliestYear) {
        data = { nameTh: currentYear };
        years.push(data);
        currentYear -= 1;
      }
      this.yearOption = years;
    },
    formatDate(date) {
      var d = new Date(date),
        month = "" + (d.getMonth() + 1),
        day = "" + d.getDate(),
        year = d.getFullYear();

      if (month.length < 2) month = "0" + month;
      if (day.length < 2) day = "0" + day;

      return [year, month, day].join("-");
    },
    checkMonth(data) {
      const variableOne = this.optionMonth.filter(
        (itemInArray) => itemInArray.id === data
      );
      return variableOne[0].name;
      // console.log(variableOne);
    },
    select(data) {
      this.data = data[0];
      this.tooltipform.ppCusId = {
        nameTh: `${this.data.nameTh != null ? this.data.nameTh : ""} ${
          this.data.familyNameTh != null ? this.data.familyNameTh : ""
        }`,
        ppCusId: this.data.ppCusId,
      };
      this.tooltipform.mobilePhone = this.data.mobilePhone;
      this.tooltipform.email = this.data.email;

      this.tooltipform.cusId = "";
      this.$refs["modalPropect"].hide();
    },

    selectCus(data) {
      // const arr = [];
      this.data = data[0];
      this.tooltipform.cusId = {
        nameTh: `${this.data.nameTh != null ? this.data.nameTh : ""} ${
          this.data.familyNameTh != null ? this.data.familyNameTh : ""
        }`,
        cusId: this.data.cusId,
      };
      this.tooltipform.mobilePhone = this.data.mobilePhone;
      this.tooltipform.email = this.data.email;
      this.tooltipform.ppCusId = "";
      this.$refs["modalCus"].hide();
    },

    selectVe(data) {
      this.data = data[0];

      this.tooltipform.vehicleId = {
        licensePlate: this.data.licensePlate,
        vehicleId: this.data.vehicleId,
      };
      this.tooltipform.vin = this.data.vin;
      this.tooltipform.vehicleModelTh = this.data.vehicleModelTh;
      this.tooltipform.vehicleBrandTh = this.data.vehicleBrandTh;
      this.$refs["modalVe"].hide();
    },
    selectCusNoArr(item) {
      this.tooltipform.mobilePhone = item.mobilePhone;
      this.tooltipform.email = item.email;
      this.tooltipform.ppCusId = "";
    },
    selectNoArr(item) {
      this.tooltipform.mobilePhone = item.mobilePhone;
      this.tooltipform.email = item.email;

      this.tooltipform.cusId = "";
    },
    selectVeNoArr(item) {
      this.tooltipform.vin = item.vin;
      this.tooltipform.vehicleModelTh = item.vehicleModelTh;
      this.tooltipform.vehicleBrandTh = item.vehicleBrandTh;
    },

    customLabelCus({ nameTh, familyNameTh }) {
      return `${nameTh != null ? nameTh : ""}  ${
        familyNameTh != null ? familyNameTh : ""
      }`;
    },
    ChangeNameTh(nameTh) {
      if (nameTh.nameTh === "นาย") {
        this.tooltipform.prefixIdEn = "Mr.";
      } else if (nameTh.nameTh === "นาง") {
        this.tooltipform.prefixIdEn = "Mrs.";
      } else {
        this.tooltipform.prefixIdEn = "Miss.";
      }
    },

    getLocalData() {
      const user = JSON.parse(localStorage.getItem(appConfig.storageUserKey));
      this.localData = user.userAccessBranch;
      this.branchItem = user.branchId;

      const localDataBranch = [];
      this.localData.forEach((item) => {
        localDataBranch.push(item.branchId);
      });

      this.localDataBranch = localDataBranch;
    },

    Form() {
      this.$v.$touch();
      this.submitform = true;
      if (this.$v.tooltipform.$invalid != true) {
        this.postData();
      }
    },
    getContactType: function () {
      // this.overlayFlag = true;
      this.loading = true; //skeleton true
      useNetw
        .get("api/master/contact-job-types-c", {})
        .then((response) => {
          this.contactTypeOption = response.data.data;
          if (this.data != null || this.data != undefined) {
            this.tooltipform.ctJobTypeId = {
              nameTh: response.data.data[0].nameTh,
              ctJobTypeId: response.data.data[0].ctJobTypeId,
            };
          }
        })
        .catch((err) => {
          if (err.response.status === 401) {
            Swal.fire(
              appConfig.swal.title.error,
              JSON.stringify(appConfig.swal.title.Unauthorization),
              // appConfig.swal.title.Unauthorization,
              appConfig.swal.type.error
              // appConfig.swal.title.Unauthorization
            );
            this.$router.push("/login");
          } else {
            Swal.fire(
              appConfig.swal.title.error,
              JSON.stringify(err.message + "<br>" + err.response.data.message),
              appConfig.swal.type.error
            );
          }
        })
        .then(() => {
          // this.overlayFlag = false;
          this.loading = false; //skeleton false
        });
    },
    getProspect: function (event) {
      // this.overlayFlag = true;
      this.loading = true; //skeleton true
      useNetw
        .get("api/contact-job/branch-prospect-customer", {
          params: {
            page: this.currentPagePros,
            perPage: this.perPagePros,
            branchId:
              this.tooltipform.branchId !== null
                ? this.tooltipform.branchId.branchId
                : this.branchId,
            nameTh: event != null ? event : this.filter.nameThPros,
            familyNameTh: this.filter.familyNameThPros,
          },
        })
        .then((response) => {
          this.prospectOption = response.data.data;
          const per_Page = response.data.perPage;
          this.from = response.data.from;
          this.totalRecordPros = response.data.total;
          this.to = response.data.to;
          this.totalPagePros = Math.ceil(
            this.totalRecordPros / per_Page
          );
        })
        .catch((err) => {
          if (err.response.status === 401) {
            Swal.fire(
              appConfig.swal.title.error,
              JSON.stringify(appConfig.swal.title.Unauthorization),
              // appConfig.swal.title.Unauthorization,
              appConfig.swal.type.error
              // appConfig.swal.title.Unauthorization
            );
            this.$router.push("/login");
          } else {
            Swal.fire(
              appConfig.swal.title.error,
              JSON.stringify(err.message + "<br>" + err.response.data.message),
              appConfig.swal.type.error
            );
          }
        })
        .then(() => {
          // this.overlayFlag = false;
          this.loading = false; //skeleton false
        });
    },
    getCustomer: function (event) {
      // this.overlayFlag = true;
      this.loading = true; //skeleton true
      useNetw
        .get("api/sale-contact-job/branch-customer", {
          params: {
            page: this.currentPage,
            perPage: this.perPage,
            branchId:
              this.tooltipform.branchId !== null
                ? this.tooltipform.branchId.branchId
                : this.branchId,
            nameTh: event != null ? event : this.filter.nameTh,
            familyNameTh: this.filter.familyNameTh,
          },
        })
        .then((response) => {
          this.customerOption = response.data.data;
          const per_Page = response.data.perPage;
          this.from = response.data.from;
          this.totalRecord = response.data.total;
          this.to = response.data.to;
          this.totalPage = Math.ceil(this.totalRecord / per_Page);
        })
        .catch((err) => {
          if (err.response.status === 401) {
            Swal.fire(
              appConfig.swal.title.error,
              JSON.stringify(appConfig.swal.title.Unauthorization),
              // appConfig.swal.title.Unauthorization,
              appConfig.swal.type.error
              // appConfig.swal.title.Unauthorization
            );
            this.$router.push("/login");
          } else {
            Swal.fire(
              appConfig.swal.title.error,
              JSON.stringify(err.message + "<br>" + err.response.data.message),
              appConfig.swal.type.error
            );
          }
        })
        .then(() => {
          // this.overlayFlag = false;
          this.loading = false; //skeleton false
        });
    },
    getVehical: function (event) {
      // this.overlayFlag = true;
      this.loading = true; //skeleton true
      useNetw
        .get("api/sale-contact-job/branch-vehicle", {
          params: {
            page: this.currentPageVe,
            perPage: this.perPageVe,
            branchId:
              this.tooltipform.branchId !== null
                ? this.tooltipform.branchId.branchId
                : this.branchId,
            licensePlate: event != null ? event : this.filter.licensePlate,
          },
        })
        .then((response) => {
          this.vehicaleOption = response.data.data;
          const per_Page = response.data.perPage;
          this.from = response.data.from;
          this.totalRecordVe = response.data.total;
          this.to = response.data.to;
          this.totalPageVe = Math.ceil(this.totalRecordVe / per_Page);
        })
        .catch((err) => {
          if (err.response.status === 401) {
            Swal.fire(
              appConfig.swal.title.error,
              JSON.stringify(appConfig.swal.title.Unauthorization),
              // appConfig.swal.title.Unauthorization,
              appConfig.swal.type.error
              // appConfig.swal.title.Unauthorization
            );
            this.$router.push("/login");
          } else {
            Swal.fire(
              appConfig.swal.title.error,
              JSON.stringify(err.message + "<br>" + err.response.data.message),
              appConfig.swal.type.error
            );
          }
        })
        .then(() => {
          // this.overlayFlag = false;
          this.loading = false; //skeleton false
        });
    },

    /**
     * Search the table data with search input
     */
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.rowAddress = filteredItems.length;
      this.currentPage = 1;
    },

    onRowSelected(rowData) {
      this.selected = rowData;
    },
    selectAllRows() {
      this.$refs.selectableTable.selectAllRows();
    },
    clearSelected() {
      this.$refs.selectableTable.clearSelected();
    },

    postData: function () {
      this.overlayFlag = true;

      useNetw
        .post("api/sales-target/store", {
          branchId: this.tooltipform.branchId.branchId,
          month: this.tooltipform.month.id,

          year: this.tooltipform.year.nameTh,
          target: this.tooltipform.target,
        })
        .then((response) => {
          Swal.fire(
            appConfig.swal.title.getSuccess,
            JSON.stringify(response.data.message),
            appConfig.swal.type.success
          );
          // btoa(
          this.$router.push({
            name: "sales-target",
          });
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(
              err.message +
                "<br>" +
                err.response.data.message +
                "<br>" +
                err.response.data.validationMessage
            ),
            appConfig.swal.type.error
          );
        })
        .then(() => {
          this.overlayFlag = false;
        });
    },

    clearAddressPost() {
      this.addressform.buildingNumber = "";
      this.addressform.townshipNumber = "";
      this.addressform.townshipName = "";
      this.addressform.subLane = "";
      this.addressform.lane = "";
      this.addressform.streetName = "";
      this.addressform.districtId = "";
      this.addressform.amphurId = "";
      this.addressform.provinceId = "";
      this.addressform.postCode = "";
      // this.fullAddress = "";
      this.submitformAddress = false;
    },

    handleSearch() {
      if (this.tooltipform.branchId === "") {
        this.branchId;
      } else {
        this.branchId = this.tooltipform.branchId.branchId;
      }
      this.getCustomer();
    },
    handleSearchVe() {
      if (this.tooltipform.branchId === "") {
        this.branchId;
      } else {
        this.branchId = this.tooltipform.branchId.branchId;
      }
      this.getVehical();
    },
    handleSearchPros() {
      if (this.tooltipform.branchId === "") {
        this.branchId;
      } else {
        this.branchId = this.tooltipform.branchId.branchId;
      }
      this.getProspect();
    },

    handleChangePage(page) {
      this.currentPage = page;
      this.getCustomer();
    },
    handlePageChange(active) {
      this.perPage = active;
      this.currentPage = 1;
      this.getCustomer();
    },
    handleChangePageVe(page) {
      this.currentPageVe = page;
      this.getVehical();
    },
    handlePageChangeVe(active) {
      this.perPageVe = active;
      this.currentPageVe = 1;
      this.getVehical();
    },

    handleChangePagePros(page) {
      this.currentPagePros = page;
      this.getProspect();
    },
    handlePageChangePros(active) {
      this.perPagePros = active;
      this.currentPagePros = 1;
      this.getProspect();
    },
  },
};
</script>
<style scoped>
.input-placeholder {
  font-size: 12px;
}
</style>
